import React, { useState, useEffect, useRef } from "react";
import { Table, Card, Space } from "lib";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import {
  Button,
  Form,
  Input,
  message,
  TablePaginationConfig,
  DatePicker,
  Descriptions,
  Row,
  Col,
  Checkbox,
} from "antd";
import { navigate } from "gatsby";
import { Download, showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import { Layout } from "layout";
import BillingMenu from "@components/Admin/Billing/BillingAssessmentSummaryMenu";
import GeneralFormModal from "@components/GeneralFormModal";
import { LeftOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
import {
  BillingInformationsClient,
  GroupReportSummaryDto,
  GroupReportDto,
} from "@api";
import { URL_PARAMETER } from "./summary";
import moment from "moment";

const defaultOrderBy = "groupReportId";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};
const pageName = "Billing";
const name = "GroupBilling";

let selectedClientGroupId = -1;
let selectedReportTemplateId = -1;

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

interface Params {
  search: string;
}
interface Props {
  location: Params;
}

const Page = ({ location }: Props) => {
  const auth = new SubpageAuth(SECTION.Admin, pageName, name);
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  if (
    location == undefined ||
    location.search == undefined ||
    location.search.length < 2
  ) {
    navigate("/400/");
    return <></>;
  }

  let searchString = location.search.substring(1);
  let searchParamArray = searchString.split("&");
  let defaultReportGenerationTimeStartDate: Date | undefined;
  let defaultReportGenerationTimeEndDate: Date | undefined;

  searchParamArray.map((value: string) => {
    let paramArray = value.split("=");
    if (
      paramArray[0] == URL_PARAMETER.CLIENT_GROUP_ID &&
      isNumeric(paramArray[1])
    ) {
      selectedClientGroupId = parseInt(paramArray[1]);
    } else if (
      paramArray[0] == URL_PARAMETER.REPORT_TEMPLATE_ID &&
      isNumeric(paramArray[1])
    ) {
      selectedReportTemplateId = parseInt(paramArray[1]);
    } else if (
      paramArray[0] == URL_PARAMETER.REPORT_GENERATION_TIME_START_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultReportGenerationTimeStartDate = new Date(parseInt(paramArray[1]));
    } else if (
      paramArray[0] == URL_PARAMETER.REPORT_GENERATION_TIME_END_DATE &&
      isNumeric(paramArray[1])
    ) {
      defaultReportGenerationTimeEndDate = new Date(parseInt(paramArray[1]));
    }
  });

  if (selectedClientGroupId == -1 || selectedReportTemplateId == -1) {
    navigate("/400/");
    return <></>;
  }

  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const isMounted = useRef(false);
  const [downloadPaginationVisible, setDownloadPaginationVisible] =
    useState(false);

  const billingInformationClient = new BillingInformationsClient();

  const [state, setState] = useState({
    pagination: defaultPagination,
    table: {
      sortField: defaultOrderBy,
      sortOrder: defaultSortOrder,
    },
    loading: true,
    reportTemplateSummaryInfo: {} as GroupReportSummaryDto,
    tableContent: [] as GroupReportDto[],
  });

  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const StaticStartDate = formatMessage({ id: "StaticStartDate" });
  const StaticEndDate = formatMessage({ id: "StaticEndDate" });

  const [filter, setFilter] = useState({
    reportGenerationTimeStartDate: defaultReportGenerationTimeStartDate as
      | Date
      | null
      | undefined,
    reportGenerationTimeEndDate: defaultReportGenerationTimeEndDate as
      | Date
      | null
      | undefined,
  });

  function getReportInformation() {
    return billingInformationClient.getBillingInformationGroupReportSummaryQuery(
      keywords,
      selectedClientGroupId, // clientGroupId
      selectedReportTemplateId, // reportTemplateId
      filter.reportGenerationTimeStartDate, // generateTimeStartDate,
      filter.reportGenerationTimeEndDate // generateTimeEndDate,
    );
  }

  function getReportDetailBillingInfoTableContent(
    sortField: string,
    sortOrder: string,
    pagination: TablePaginationConfig
  ) {
    return billingInformationClient.getBillingInformationGroupReportWithPaginationQuery(
      keywords,
      selectedClientGroupId, // clientGroupId
      selectedReportTemplateId, // reportTemplateId
      filter.reportGenerationTimeStartDate, // generateTimeStartDate,
      filter.reportGenerationTimeEndDate, // generateTimeEndDate,
      pagination.current, // pageNumber,
      pagination.pageSize, // pageSize,
      capitalizeFirstLetter(sortField), // orderBy
      sortOrder == "ascend" // isOrderByAsc
    );
  }

  useEffect(() => {
    fetchItem(state.table.sortField, state.table.sortOrder, state.pagination);
  }, []);

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    let sortField =
      Object.keys(sorter).length == 0 ? defaultOrderBy : sorter.field;
    let sortOrder =
      Object.keys(sorter).length == 0 ? defaultSortOrder : sorter.order;
    fetchItem(sortField, sortOrder, pagination);
  }

  function fetchItemForDowndropChange() {
    fetchItem(state.table.sortField, state.table.sortOrder, defaultPagination);
  }

  async function fetchItem(
    sortField: string,
    sortOrder: string,
    pagination: TablePaginationConfig
  ) {
    setState({
      ...state,
      loading: true,
    });
    try {
      Promise.all([
        getReportInformation(),
        getReportDetailBillingInfoTableContent(
          sortField,
          sortOrder,
          pagination
        ),
      ]).then((values) => {
        setState({
          ...state,
          reportTemplateSummaryInfo:
            (values[0] as GroupReportSummaryDto) ||
            ({} as GroupReportSummaryDto),
          tableContent:
            (values[1].items as GroupReportDto[]) || ([] as GroupReportDto[]),
          pagination: {
            ...state.pagination,
            ...pagination,
            total: values[1].totalCount,
          },
          loading: false,
        });
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
  }

  const columns = [
    {
      // title: "Group Report ID",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGroupReportId" />,
      dataIndex: "groupReportId",
      key: "groupReportId",
      sorter: true,
    },
    {
      // title: "Group Report Template Name",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGroupReportTemplateName" />,
      dataIndex: "groupReportTemplateName",
      key: "groupReportTemplateName",
      sorter: true,
    },
    {
      // title: "Norm",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderNorm" />,
      dataIndex: "normName",
      key: "normName",
      sorter: true,
    },
    {
      // title: "Group Report Language",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGroupReportLanguage" />,
      dataIndex: "reportLanguage",
      key: "reportLanguage",
      sorter: true,
    },
    {
      // title: "Generate Time",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGenerateTime" />,
      dataIndex: "generateTime",
      key: "generateTime",
      sorter: true,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "No. of Group Member",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderUserCount" />,
      dataIndex: "userCount",
      key: "userCount",
      sorter: true,
    },
    {
      // title: "Generate Person Name",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGeneratePersonName" />,
      dataIndex: "fullname",
      key: "fullname",
      sorter: true,
    },
    {
      // title: "Generate Person Login ID",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGeneratePersonID" />,
      dataIndex: "username",
      key: "username",
      sorter: true,
    },
    {
      // title: "Generate Person Email",
      title: <FormattedMessage id="BillingGroupDetailTableHeaderGeneratePersonEmail" />,
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
  ];

  const keywordElement = (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={handleKeywordsSearch}
    >
      <Form.Item name="keywords">
        <Input placeholder={StaticKeywords} disabled={state.loading} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit" disabled={state.loading}>
            <FormattedMessage id="StaticSearchLabel" />
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  const handleChangeReportGenerationDate = (values: any) => {
    if (values == null) {
      setFilter((prev) => ({
        ...prev,
        reportGenerationTimeStartDate: undefined,
        reportGenerationTimeEndDate: undefined,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        reportGenerationTimeStartDate: values[0],
        reportGenerationTimeEndDate: values[1],
      }));
    }
  };

  const handleDownloadExcel = async (data: any) => {
    setDownloadPaginationVisible(false);
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );

    setState({ ...state, loading: true });

    try {
      const res =
        await billingInformationClient.exportBillingInformationGroupReport(
          keywords,
          selectedClientGroupId, // clientGroupId
          selectedReportTemplateId, // reportTemplateId
          filter.reportGenerationTimeStartDate, // generateTimeStartDate,
          filter.reportGenerationTimeEndDate, // generateTimeEndDate,
          data.pageNumber, // pageNumber,
          data.pageSize, // pageSize,
          capitalizeFirstLetter(state.table.sortField), // orderBy
          state.table.sortOrder == "ascend" // isOrderByAsc
        );
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchItemForDowndropChange();
    } else {
      isMounted.current = true;
    }
  }, [filter, keywords]);

  let queryLabelSpan = 8;
  let queryDataSpan = 16;

  return (
    <Layout title="BillingGroupReportSummaryNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <BillingMenu />
          <Row justify="space-between">
            <Col xs={24} sm={12}>
              <Space justify="flex-start">
                <Col>
                  <Button
                    ghost
                    type="primary"
                    shape="round"
                    onClick={() => {
                      let url = "/admin/billing/group/summary";
                      navigate(url);
                    }}
                  >
                    <LeftOutlined />
                  </Button>
                </Col>
                <Col>
                  <Space justify="flex-start">{keywordElement}</Space>
                </Col>
              </Space>
            </Col>
          </Row>
          {Object.keys(state.reportTemplateSummaryInfo).length > 0 ? (
            <Space justify="flex-start">
              <Descriptions
                contentStyle={{ width: "250px" }}
                column={{ xs: 1, sm: 2 }}
                size="small"
                bordered
              >
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingGroupReportDetailContentReportLabel" />
                  }
                >
                  {state.reportTemplateSummaryInfo?.groupReport ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingGroupReportDetailContentTotalReportLabel" />
                  }
                >
                  {state.reportTemplateSummaryInfo?.reportCount ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingGroupReportDetailContentParentGroupLabel" />
                  }
                >
                  {state.reportTemplateSummaryInfo?.parentGroup ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <FormattedMessage id="BillingGroupReportDetailContentGroupLabel" />
                  }
                >
                  {state.reportTemplateSummaryInfo?.group ?? (
                    <FormattedMessage id="StaticNaLabel" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Space>
          ) : null}
          <Row justify="space-between" align="bottom">
            <Col>
              <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                <Col span={queryLabelSpan}><FormattedMessage id="BillingGroupSummaryFilterLabelReportGenerationDate" />:</Col>
                <Col span={queryDataSpan}>
                  <RangePicker
                    placeholder={[StaticStartDate, StaticEndDate]}
                    showTime
                    allowEmpty={[true, true]}
                    onChange={handleChangeReportGenerationDate}
                    disabled={state.loading}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setDownloadPaginationVisible(true)}
                disabled={state.loading}
              >
                <Space>
                  <FormattedMessage id="StaticExportTableButton" />
                </Space>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={[...state.tableContent]}
            columns={columns}
            rowKey={(r) => r.reportId}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={downloadPaginationVisible}
        handleAction={handleDownloadExcel}
        handleCancelAction={() => setDownloadPaginationVisible(false)}
        titleId={"BillingGroupReportDetailModalExportTableTitle"}
        okTextId={"StaticDownloadButton"}
        editableFields={[
          {
            name: "pageSize",
            labelId: "StaticPageSizeLabel",
            type: "number",
            rules: [
              {
                required: true,
                message: <FormattedMessage id="StaticMissingPageSizeRule" />,
              },
            ],
          },
          {
            name: "pageNumber",
            labelId: "StaticPageNumberLabel",
            type: "number",
            rules: [
              {
                required: true,
                message: <FormattedMessage id="StaticMissingPageNumberRule" />,
              },
            ],
          },
        ]}
      />
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
